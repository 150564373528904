import React, { type SVGAttributes } from 'react';

export const IconFlagMySvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#cc0001" d="M0 3h24v18H0V3z" />
    <path
      fill="#fff"
      d="M0 19.71h24V21H0v-1.29zm0-2.57h24v1.29H0v-1.29zm0-2.57h24v1.29H0v-1.29zM0 12h24v1.29H0V12zm0-2.57h24v1.28H0V9.43zm0-2.58h24v1.28H0V6.85zm0-2.57h24v1.29H0V4.28z"
    />
    <path fill="#010066" d="M0 3h13.97v10.29H0V3z" />
    <g fill="#fc0">
      <path d="M5.61 4.82a3.32 3.32 0 0 0-3.33 3.32c0 1.84 1.5 3.32 3.33 3.32.64 0 1.27-.18 1.8-.53-1.54.54-3.22-.28-3.76-1.82-.1-.3-.15-.63-.15-.96 0-1.63 1.32-2.95 2.96-2.95.35 0 .7.06 1.02.18a3.42 3.42 0 0 0-1.87-.56z" />
      <path d="M11.14 9.87l-1.4-.73.42 1.5-.95-1.26-.29 1.53-.29-1.53-.94 1.25.42-1.5-1.41.73 1.05-1.17-1.59.06 1.46-.61-1.46-.62 1.59.07-1.04-1.18 1.4.73-.41-1.49.94 1.25.29-1.53.29 1.53.94-1.25-.42 1.5 1.41-.73-1.05 1.17 1.59-.06-1.46.61 1.46.62-1.59-.06 1.04 1.17z" />
    </g>
  </svg>
);
