import type { Partners } from 'src/types';

export const partners: Partners = {
  label: 'International partners',
  links: [
    {
      title: 'Bdjobs (Bangladesh)',
      url: 'https://bdjobs.com/',
    },
    {
      title: 'Jobstreet (S.E. Asia)',
      url: 'https://www.jobstreet.com/',
    },
    {
      title: 'Jora (Worldwide)',
      url: 'https://www.jora.com/',
    },
    {
      title: 'Seaman Jobsite (Philippines)',
      url: 'https://seamanjobsite.workabroad.ph/',
    },
    {
      title: 'SEEK (Australia)',
      url: 'https://www.seek.com.au/',
    },
    {
      title: 'SEEK (New Zealand)',
      url: 'https://www.seek.co.nz/',
    },
    {
      title: 'Work Abroad (Philippines)',
      url: 'https://www.workabroad.ph/',
    },
    {
      title: 'Workana (Latin America)',
      url: 'https://www.workana.com/',
    },
  ],
};
