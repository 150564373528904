import type { Brand } from '@seek/melways-sites';
import {
  Accordion,
  AccordionItem,
  Box,
  Card,
  Link,
  Stack,
  Text,
} from 'braid-design-system';

import { useAnalyticsContext } from 'src/App/contexts/AnalyticsContext';
import { partners as jdbPartners } from 'src/brands/jobsdb/partners';
import { partners as jsPartners } from 'src/brands/jobstreet/partners';
import type { LinkProps } from 'src/types';

import * as styles from './UpperFooter.css';

export const UpperFooter = ({ brand }: { brand: Brand }) => {
  const analytics = useAnalyticsContext();
  const { label, links } = brand === 'jobsdb' ? jdbPartners : jsPartners;

  const handleClick = (title: string) =>
    analytics.navigationPressed({
      linkText: title,
      linkPosition: 'footer',
    });

  const handleToggle = (expanded: boolean, linkText: string) =>
    analytics.navigationPressed({
      linkText,
      linkAction: expanded ? 'expand' : 'collapse',
    });

  return (
    <Card>
      <Accordion>
        <AccordionItem
          id="intl-partners"
          label={label}
          onToggle={(expanded) => handleToggle(expanded, label)}
        >
          <Box marginY="small">
            <Stack space="large">
              {links.map(({ title, url }: LinkProps) => (
                <Link
                  key={title}
                  href={url}
                  className={styles.link}
                  onClick={() => handleClick(title)}
                >
                  <Text tone="secondary">{title}</Text>
                </Link>
              ))}
            </Stack>
          </Box>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
