import React, { type SVGAttributes } from 'react';

export const IconFlagThSvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#fff" d="M.44 3.33h23.11v17.33H.44V3.33z" />
    <path fill="#001b9a" d="M.44 9.2h23.11v5.78H.44V9.2z" />
    <path
      fill="#e70000"
      d="M.44 3.33h23.11v2.98H.44V3.33zm0 14.45h23.11v2.89H.44v-2.89z"
    />
    <path
      fill="#ccc"
      d="M24 21.11H0V2.89h24v18.22zM.89 20.22h22.22V3.78H.89v16.44z"
    />
  </svg>
);
