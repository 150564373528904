import type { SeoProps } from 'src/types';

export const seo: SeoProps = {
  description:
    "Search jobs & career advice with Jobsdb - Asia's preferred destination for job seekers. Find job vacancies across Hong Kong, Singapore & Thailand - all with Jobsdb!",
  title: 'Jobsdb -  Search jobs across Hong Kong, Singapore & Thailand',
  url: 'https://www.jobsdb.com/',
  robots: 'index,follow',
  facebookDomainVerification: 'c3ad48fhjbjoi4cez7zbbpu0y8vhr6',
};
