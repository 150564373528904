import type { Brand } from '@seek/melways-sites';
import { Box, ContentBlock } from 'braid-design-system';
import React from 'react';

import { CountryLinks } from './components/CountryLinks/CountryLinks';

import * as styles from './CountrySelection.css';

export const CountrySelection = ({ brand }: { brand: Brand }) => (
  <Box
    data-testid="country-selection"
    className={styles.countrySelection}
    padding={['small', 'none']}
  >
    <ContentBlock width="small">
      <Box
        background="body"
        borderRadius="standard"
        margin={['xsmall', 'xlarge']}
        paddingX={['small', 'none']}
        paddingY={['medium', 'none']}
      >
        <Box padding={['xlarge', 'xlarge']}>
          <CountryLinks brand={brand} />
        </Box>
      </Box>
    </ContentBlock>
  </Box>
);
