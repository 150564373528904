import { useMelwaysLink } from '@seek/melways-react';

import type { CountryExtended } from 'src/types';

export const useCountryUrls = (): Record<CountryExtended, string> => ({
  au: useMelwaysLink({
    path: '/',
    country: 'au',
  }),
  nz: useMelwaysLink({
    path: '/',
    country: 'nz',
  }),
  hk: useMelwaysLink({
    path: '/',
    country: 'hk',
  }),
  jobsdb_sg: 'https://sg.jobsdb.com/',
  th: useMelwaysLink({
    path: '/',
    country: 'th',
  }),
  id: useMelwaysLink({
    path: '/',
    country: 'id',
  }),
  my: useMelwaysLink({
    path: '/',
    country: 'my',
  }),
  ph: useMelwaysLink({
    path: '/',
    country: 'ph',
  }),
  sg: useMelwaysLink({
    path: '/',
    country: 'sg',
  }),
  vn: 'https://www.jobstreet.vn',
});
