import type { Brand } from '@seek/melways-sites';
import { Box } from 'braid-design-system';
import React from 'react';

import { LowerFooter } from './components/LowerFooter/LowerFooter';
import { UpperFooter } from './components/UpperFooter/UpperFooter';

export const Footer = ({ brand }: { brand: Brand }) => (
  <Box padding={['small', 'xlarge']} style={{ minHeight: '25vh' }}>
    <UpperFooter brand={brand} />
    <LowerFooter brand={brand} />
  </Box>
);
