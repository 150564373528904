import React, { type SVGAttributes } from 'react';

export const IconFlagPhSvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#0038a8" d="M0 3.05h24v8.9H0v-8.9z" />
    <path fill="#ce1126" d="M0 11.95h24v9H0v-9z" />
    <path fill="#fff" d="M15.42 11.95L0 20.85V3.05" />
    <path
      fill="#fcd116"
      d="M9.07 12.18l.23-.23-.23-.23-1.77.11c0-.04-.02-.07-.02-.11l1.62-.21-.19-.19-1.45.29c-.04-.23-.11-.46-.24-.65l1.21-.81v-.27l-1.28.99c-.02-.03-.05-.06-.07-.09L8.2 9.62v-.33h-.33l-1.16 1.32c-.03-.02-.06-.05-.09-.07l.99-1.28h-.27l-.81 1.21c-.19-.13-.41-.22-.64-.26l.28-1.43-.19-.19-.21 1.61c-.04 0-.08-.01-.11-.01l.12-1.76-.24-.24-.23.23.12 1.76c-.04 0-.07.01-.11.02L5.1 8.59l-.19.19.29 1.45c-.23.05-.45.13-.64.25l-.82-1.22h-.27l.99 1.29c-.03.02-.06.05-.09.07L3.21 9.29h-.33v.33l1.33 1.16a.55.55 0 0 1-.07.09l-1.29-.99v.27l1.22.82c-.12.19-.22.4-.27.64l-1.44-.29-.19.19 1.61.21c-.01.04-.02.07-.02.11L2 11.71l-.23.23.23.23 1.76-.12c0 .04.01.07.02.11l-1.61.21.19.19 1.44-.29c.05.23.15.44.28.63l-1.24.83V14l1.31-1c.02.03.05.05.08.08l-1.35 1.18v.33h.33l1.18-1.35.09.06-1.01 1.32h.27l.84-1.25c.19.13.39.23.62.28l-.29 1.46.19.19.21-1.62c.04 0 .07.02.11.03l-.12 1.75.23.23.23-.23-.12-1.76c.04 0 .08-.01.11-.01l.21 1.6.19-.19-.29-1.43c.23-.05.44-.15.63-.28l.82 1.23h.27l-.99-1.29c.03-.02.07-.04.09-.06l1.16 1.32h.36v-.33L6.88 13.1c.03-.03.04-.06.06-.1l1.29.99v-.27L7 12.93c.13-.19.23-.4.28-.63l1.43.28.19-.19-1.6-.21c0-.04.01-.08.01-.11l1.76.11zm-7.3-6.82l.28-.65-.62.34-.54-.46.13.7-.6.36.7.09.16.69.3-.64.71.06zm11.2 6.36l-.01-.71-.43.56-.68-.2.4.58-.4.58.68-.2.43.56.01-.71.67-.23zM1.61 18.11l-.31-.63-.16.69-.7.09.61.37-.12.69.53-.47.62.34-.28-.65.51-.49z"
    />
  </svg>
);
