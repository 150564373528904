import type { SeoProps } from 'src/types';

export const seo: SeoProps = {
  description:
    'Search job vacancies & get career advice with Jobstreet. Find jobs across Asia and get hired - all with Jobstreet!',
  title:
    "Jobstreet - Find your next job and get hired on Asia's favourite career site!",
  url: 'https://www.jobstreet.com/',
  alternateLinkList: [
    {
      rel: 'alternate',
      hreflang: 'x-default',
      href: 'https://www.jobstreet.com/',
    },
    {
      rel: 'alternate',
      hreflang: 'id-id',
      href: 'https://id.jobstreet.com/',
    },
    {
      rel: 'alternate',
      hreflang: 'en-id',
      href: 'https://id.jobstreet.com/en',
    },
    {
      rel: 'alternate',
      hreflang: 'en-my',
      href: 'https://my.jobstreet.com/',
    },
    {
      rel: 'alternate',
      hreflang: 'en-sg',
      href: 'https://sg.jobstreet.com/',
    },
    {
      rel: 'alternate',
      hreflang: 'en-ph',
      href: 'https://ph.jobstreet.com/',
    },
  ],
  robots: 'index,follow',
};
