import { Box, Divider, Stack } from 'braid-design-system';
import React from 'react';

import { InformationFooter } from './components/InformationFooter/InformationFooter';
import { InternationalPartners } from './components/InternationalPartners/InternationalPartners';

export const SeekFooter = () => (
  <Stack space="large">
    <Divider />
    <Box
      display="flex"
      justifyContent={{
        mobile: 'flexStart',
        tablet: 'spaceBetween',
      }}
      flexDirection={{
        mobile: 'column',
        desktop: 'row',
      }}
      flexWrap={{
        wide: 'wrap',
      }}
      paddingBottom="xlarge"
    >
      <InternationalPartners />
      <InformationFooter />
    </Box>
  </Stack>
);
