import 'braid-design-system/reset';
import type { Brand } from '@seek/melways-sites';
import { metrics } from '@seek/metrics-js';
import { Box, BraidProvider, ContentBlock, Stack } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';
import React, { useEffect } from 'react';

import { SeekFooter } from './components/Seek/SeekFooter/SeekFooter';
import { SeekLandingPage } from './components/Seek/SeekLandingPage';
import { CountrySelection } from './components/SeekAsia/CountrySelection/CountrySelection';
import { Footer } from './components/SeekAsia/Footer/Footer';
import { Header } from './components/SeekAsia/Header/Header';
import { Seo } from './components/Seo/Seo';

export interface AppProps {
  brand: Brand;
}

export const App = ({ brand }: AppProps) => {
  useEffect(() => metrics.count('page_rendered'), []);
  return (
    <BraidProvider theme={brand === 'seek' ? seekJobs : apac}>
      <Box style={{ minHeight: '100vh', backgroundColor: 'white' }}>
        <Seo brand={brand} />
        {brand === 'seek' ? (
          <Box
            paddingX={{
              mobile: 'xsmall',
              tablet: 'gutter',
            }}
          >
            <ContentBlock width="large">
              <Stack space={['small', 'xlarge']}>
                <SeekLandingPage />
                <SeekFooter />
              </Stack>
            </ContentBlock>
          </Box>
        ) : (
          <>
            <Header brand={brand} />
            <CountrySelection brand={brand} />
            <Footer brand={brand} />
          </>
        )}
      </Box>
    </BraidProvider>
  );
};
