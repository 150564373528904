import {
  Box,
  Column,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
  useSpace,
} from 'braid-design-system';
import React from 'react';

import { JDBBrandLogo } from '../Logo/JDBBrandLogo';
import { JSBrandLogo } from '../Logo/JSBrandLogo';
import { SeekLogo } from '../Logo/SeekLogo';

import { SeekCountrySelection } from './SeekCountrySelection/SeekCountrySelection';
import { SeekIllustrationSvg } from './SeekIllustrationSvg';

import * as styles from './SeekLandingPage.css';

export const SeekLandingPage = () => {
  const { grid } = useSpace();

  return (
    <Box
      paddingTop={{
        mobile: 'gutter',
        tablet: 'xxlarge',
      }}
      paddingBottom="medium"
    >
      <Stack space={['large', 'xlarge']}>
        <SeekLogo />
        <Columns space={['none', 'xlarge']} collapseBelow="tablet">
          <Column width="2/5">
            <Stack space={['large', 'xlarge']}>
              <Box paddingBottom={['none', 'medium']}>
                <Heading level="1">Explore jobs and talent</Heading>
              </Box>
              <Box paddingBottom="xsmall">
                <SeekCountrySelection />
              </Box>
              <Stack space="small">
                <Text>SEEK brands</Text>
                <Inline space="xsmall">
                  <Box paddingRight="small">
                    <JSBrandLogo height={grid * 5} />
                  </Box>
                  <JDBBrandLogo height={grid * 5} />
                </Inline>
              </Stack>
            </Stack>
          </Column>
          <Column width="3/5">
            <Box
              display="flex"
              justifyContent="center"
              className={styles.illustration}
            >
              <SeekIllustrationSvg />
            </Box>
          </Column>
        </Columns>
      </Stack>
    </Box>
  );
};
