import { Box, Inline, Link, Text } from 'braid-design-system';
import React from 'react';

import { copyright } from 'src/brands/seek/copyright';

import { useFooterLinks } from './UseFooterLinks';

import * as styles from './InformationFooter.css';

export const InformationFooter = () => {
  const footerLinks = useFooterLinks();
  return (
    <Box paddingTop={['gutter', 'gutter', 'none']}>
      <Inline collapseBelow="desktop" space="gutter">
        {footerLinks.map(({ title, url }) => (
          <Text key={title} size="small" tone="secondary">
            <Link href={url} className={styles.link}>
              {title}
            </Link>
          </Text>
        ))}
        <Text data={{ testid: 'copyright' }} size="small" tone="secondary">
          {copyright.copyright}
        </Text>
      </Inline>
    </Box>
  );
};
