import { useMelwaysLink } from '@seek/melways-react';
import type { Brand } from '@seek/melways-sites';

import type { LinkProps } from 'src/types';

export const useFooterLinks = (brand: Brand): LinkProps[] => {
  const jsDefaultCountry = 'my';
  const jdbDefaultCountry = 'hk';

  const country = brand === 'jobstreet' ? jsDefaultCountry : jdbDefaultCountry;
  const aboutLabel = brand === 'jobstreet' ? 'About Jobstreet' : 'About Jobsdb';
  return [
    {
      title: aboutLabel,
      url: useMelwaysLink({
        country,
        path: '/about',
      }),
    },
    {
      title: 'Privacy statement',
      url: useMelwaysLink({
        country,
        path: '/my-privacy',
      }),
    },
    {
      title: 'Terms & conditions',
      url: useMelwaysLink({
        country,
        path: '/terms',
      }),
    },
  ];
};
