import React, { type SVGAttributes } from 'react';

export const IconFlagSgSvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#fff" d="M.57 3.43h22.86v17.14H.57V3.43z" />
    <path fill="#df0000" d="M.57 3.43h22.86V12H.57V3.43z" />
    <path
      fill="#fff"
      d="M5.79 4.87c-1.19.27-2.07 1.15-2.32 2.34-.34 1.62.72 3.21 2.35 3.55-1.63.47-3.33-.47-3.8-2.1v-.01c-.48-1.62.47-3.32 2.1-3.78.57-.16 1.09-.16 1.67 0z"
    />
    <path
      fill="#fff"
      d="M5.32 7.36l.18.54-.47-.33-.46.33.17-.54-.45-.34.57-.01.18-.54.18.54h.57l-.47.35zm.63 1.85l.17.54-.46-.33-.46.34.17-.54-.46-.34h.57l.18-.54.18.54h.56l-.45.33zm2.08-.01l.18.54-.46-.33-.46.33.17-.54L7 8.87l.57-.01.18-.54.18.54h.57l-.47.34zm.63-1.84l.17.54-.46-.33-.46.33.17-.54-.46-.34h.57l.18-.54.18.54h.57l-.46.34zM7 6.13l.18.54-.46-.33-.46.33.17-.54-.46-.33.57-.01.18-.54.18.54h.57L7 6.13z"
    />
    <path fill="#ccc" d="M24 21.14H0V2.86h24v18.28zM1.14 20h21.71V4H1.14v16z" />
  </svg>
);
