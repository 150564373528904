import type { CountryExtended, LinkProps } from 'src/types';

export const countryLinks = (
  countryUrls: Record<CountryExtended, string>,
): LinkProps[] => [
  {
    title: 'Australia',
    url: countryUrls.au,
  },
  {
    title: 'New Zealand',
    url: countryUrls.nz,
  },
  {
    title: 'Hong Kong',
    url: countryUrls.hk,
  },
  {
    title: 'Philippines',
    url: countryUrls.ph,
  },
  {
    title: 'Indonesia',
    url: countryUrls.id,
  },
  {
    title: 'Singapore',
    url: countryUrls.sg,
  },
  {
    title: 'Malaysia',
    url: countryUrls.my,
  },
  {
    title: 'Thailand',
    url: countryUrls.th,
  },
];
