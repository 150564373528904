import { IconFlagHkSvg } from 'src/icons/IconFlagHkSvg';
import { IconFlagSgSvg } from 'src/icons/IconFlagSgSvg';
import { IconFlagThSvg } from 'src/icons/IconFlagThSvg';
import type { CountryExtended, CountryLink } from 'src/types';

export const countryLinks = (
  countryUrls: Record<CountryExtended, string>,
): CountryLink[] => {
  const defaultCountrySequence = ['hk', 'sg', 'th'];

  const countryLinkMap: Record<string, CountryLink> = {
    hk: {
      title: 'Hong Kong',
      url: countryUrls.hk,
      iconFlag: IconFlagHkSvg,
    },
    sg: {
      title: 'Singapore',
      url: countryUrls.jobsdb_sg,
      iconFlag: IconFlagSgSvg,
    },
    th: {
      title: 'Thailand',
      url: countryUrls.th,
      iconFlag: IconFlagThSvg,
    },
  };

  return defaultCountrySequence.map(
    (countryName) => countryLinkMap[countryName] || countryLinkMap.hk,
  );
};
