import React, { type SVGAttributes } from 'react';

export const IconFlagIdSvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#e70011" d="M.44 2.98h23.11v9.24H.44V2.98z" />
    <path fill="#fff" d="M.44 12.22h23.11v7.99H.44v-7.99z" />
    <path
      fill="#ccc"
      d="M0 2.53v18.22h24V2.53H0zm23.11 17.34H.89V3.42h22.22v16.45z"
    />
  </svg>
);
