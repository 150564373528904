import type { Analytics } from '@seek/seek-jobs-analytics';

export const REDIRECT_SELECTION_LOCAL_STORAGE_KEY = 'selection';

export const onLinkClick =
  (
    countrySite: string,
    countryName: string,
    rememberSelection: boolean,
    analytics: Analytics,
  ) =>
  () => {
    analytics.landingPageCountrySelectionPressed({
      linkText: countryName,
      isRemember: rememberSelection,
    });

    if (rememberSelection) {
      return window.localStorage.setItem(
        REDIRECT_SELECTION_LOCAL_STORAGE_KEY,
        countrySite,
      );
    }

    return undefined;
  };
