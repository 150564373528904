import { useMelwaysLink } from '@seek/melways-react';

import type { LinkProps } from 'src/types';

export const useFooterLinks = (): LinkProps[] => [
  {
    title: 'Privacy policy',
    url: useMelwaysLink({
      country: 'au',
      path: '/my-privacy',
    }),
  },
  {
    title: 'Terms & conditions',
    url: useMelwaysLink({
      country: 'au',
      path: '/terms',
    }),
  },
];
