import {
  Box,
  Checkbox,
  Stack,
  Text,
  TextLink,
  Tiles,
} from 'braid-design-system';
import { useState } from 'react';

import { useAnalyticsContext } from 'src/App/contexts/AnalyticsContext';
import { countryLinks as seekCountryLinks } from 'src/brands/seek/countryLinks';
import { onLinkClick } from 'src/utils/onLinkClick';
import { useCountryUrls } from 'src/utils/useCountryUrls';

import * as styles from './SeekCountrySelection.css';

export const SeekCountrySelection = () => {
  const analytics = useAnalyticsContext();
  const [rememberSelection, setRememberSelection] = useState<boolean>(false);

  const countryUrls = useCountryUrls();
  const countryLinks = seekCountryLinks(countryUrls);
  return (
    <Stack space={['large', 'xlarge']}>
      <Stack space="large">
        <Text size="standard">Select your country</Text>
        <Box maxWidth="small">
          <Stack space="large">
            <Tiles space="large" columns={2}>
              {countryLinks.map(({ title, url }) => (
                <Box key={title} className={styles.countryLink}>
                  <Text>
                    <TextLink
                      key={title}
                      href={url}
                      onClick={onLinkClick(
                        url,
                        title,
                        rememberSelection,
                        analytics,
                      )}
                    >
                      {title}
                    </TextLink>
                  </Text>
                </Box>
              ))}
            </Tiles>
          </Stack>
        </Box>
      </Stack>
      <Checkbox
        id="remember-selection"
        checked={rememberSelection}
        onChange={() => setRememberSelection(!rememberSelection)}
        label="Remember my selection"
      />
    </Stack>
  );
};
