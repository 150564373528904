export const sites = [
  {
    name: 'candidate-seek-root',
    host: 'dev.seek.com',
    routes: ['/'],
  },
  { name: 'candidate-jobsdb-root', host: 'dev.jobsdb.com', routes: ['/'] },
  {
    name: 'candidate-jobstreet-root',
    host: 'dev.jobstreet.com',
    routes: ['/'],
  },
] as const;

export const environments = ['local', 'staging', 'production'] as const;

export type Environment = (typeof environments)[number];
export type SiteName = (typeof sites)[number]['name'];

interface EnvConfig {
  METRICS_URL: string;
  METRICS_IS_ENABLED: boolean;
}

const local: EnvConfig = {
  METRICS_URL: '',
  METRICS_IS_ENABLED: false,
};

const staging: EnvConfig = {
  METRICS_URL: 'https://discover-metrics.staging.cloud.seek.com.au',
  METRICS_IS_ENABLED: false,
};

const production: EnvConfig = {
  METRICS_URL: 'https://discover-metrics.cloud.seek.com.au',
  METRICS_IS_ENABLED: true,
};

export const envConfig: Record<Environment, EnvConfig> = {
  local,
  staging,
  production,
};
