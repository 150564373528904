import React from 'react';

export const SeekIllustrationSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 305">
    <path
      fill="#051a49"
      d="M432 16c0 53.02-42.98 96-96 96s-96-42.98-96-96h192ZM480 160c-26.51 0-48-21.49-48-48h48v48Z"
    />
    <path
      fill="#e60278"
      d="M144 16c13.255 0 24 10.745 24 24s-10.745 24-24 24-24-10.745-24-24 10.745-24 24-24ZM384 208c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.49-48 48-48Zm-16.202 48c0 8.948 7.254 16.202 16.202 16.202 8.948 0 16.202-7.254 16.202-16.202 0-8.948-7.254-16.202-16.202-16.202-8.948 0-16.202 7.254-16.202 16.202ZM48 208c0-53.02 42.98-96 96-96s96 42.98 96 96h-63.596c0-17.896-14.508-32.404-32.404-32.404S111.596 190.104 111.596 208H48Z"
    />
  </svg>
);
