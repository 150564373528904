import type { Brand } from '@seek/melways-sites';
import {
  Checkbox,
  Heading,
  IconRenderer,
  Inline,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import React, { useState } from 'react';

import { useAnalyticsContext } from 'src/App/contexts/AnalyticsContext';
import { onLinkClick } from 'src/utils/onLinkClick';

import { useCountryLinks } from './UseCountryLinks';

export const CountryLinks = ({ brand }: { brand: Brand }) => {
  const analytics = useAnalyticsContext();
  const links = useCountryLinks(brand);

  const [rememberSelection, setRememberSelection] = useState<boolean>(false);

  return (
    <Stack space="large">
      <Heading level="3">{'Search for jobs in'}</Heading>
      <Stack space="gutter">
        {links.map(({ title, iconFlag: IconFlag, url }) => (
          <Text weight="medium" key={title}>
            <TextLink
              href={url}
              onClick={onLinkClick(url, title, rememberSelection, analytics)}
            >
              <Inline alignY="center" space="small">
                <IconRenderer>
                  {({ className }) => <IconFlag className={className} />}
                </IconRenderer>
                <>{title}</>
              </Inline>
            </TextLink>
          </Text>
        ))}
      </Stack>
      <Checkbox
        id="remember-selection"
        checked={rememberSelection}
        onChange={(newSelection) =>
          setRememberSelection(newSelection.currentTarget.checked)
        }
        label="Remember my selection"
      />
    </Stack>
  );
};
