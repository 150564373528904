import type { Brand } from '@seek/melways-sites';
import { Box, Columns, Column, Inline, Link, Text } from 'braid-design-system';
import React from 'react';

import { useAnalyticsContext } from 'src/App/contexts/AnalyticsContext';
import { copyright as jdbCopyright } from 'src/brands/jobsdb/copyright';
import { copyright as jsCopyright } from 'src/brands/jobstreet/copyright';

import { useFooterLinks } from './UseFooterLinks';

import * as styles from './LowerFooter.css';

export const LowerFooter = ({ brand }: { brand: Brand }) => {
  const analytics = useAnalyticsContext();
  const links = useFooterLinks(brand);
  const handleClick = (title: string) =>
    analytics.navigationPressed({
      linkText: title,
      linkPosition: 'footer',
    });

  return (
    <Columns collapseBelow="tablet" space="none" alignY="center">
      <Column>
        <Box padding="medium">
          <Inline collapseBelow="tablet" space="xlarge">
            {links.map(({ title, url }) => (
              <Text
                key={title}
                size="small"
                tone="secondary"
                align={['center', 'left']}
              >
                <Link
                  href={url}
                  className={styles.link}
                  onClick={() => handleClick(title)}
                >
                  {title}
                </Link>
              </Text>
            ))}
          </Inline>
        </Box>
      </Column>
      <Column width="1/3">
        <Box padding="medium">
          <Text
            data={{ testid: 'copyright' }}
            size="small"
            tone="secondary"
            align={['center', 'right']}
          >
            {brand === 'jobsdb'
              ? jdbCopyright.copyright
              : jsCopyright.copyright}
          </Text>
        </Box>
      </Column>
    </Columns>
  );
};
