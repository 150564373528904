import { Logo } from '@seek/logo';
import { Box } from 'braid-design-system';

const defaultVariant = 'standard';
const brand = 'seek';
const defaultSizeType = 'fill';

import * as styles from './SeekLogo.css';

export const SeekLogo = ({ isInvert = false }) => (
  <Box className={styles.seekLogoSize}>
    <Logo
      variant={isInvert ? 'inverted' : defaultVariant}
      brand={brand}
      size={defaultSizeType}
    />
  </Box>
);
