import { device } from '@seek/ca-myprofile-utils';
import { MelwaysProvider } from '@seek/melways-react';
import { setup as setupMetrics, metrics } from '@seek/metrics-js';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { App } from './App/App';
import {
  AnalyticsProvider,
  createAnalyticsHelper,
} from './App/contexts/AnalyticsContext';
import { envConfig } from './config';
import type { ClientContext } from './types';
import { REDIRECT_SELECTION_LOCAL_STORAGE_KEY } from './utils/onLinkClick';

export default ({ brand, environment, route, site }: ClientContext) => {
  const { METRICS_URL, METRICS_IS_ENABLED } = envConfig[environment];

  const globalTags = [
    `brand:${brand}`,
    `route:${route}`,
    `app-env:${environment}`,
    `browser-name:${device.getBrowserName()}`,
    `device-type:${device.getDeviceType()}`,
  ];

  setupMetrics({
    host: METRICS_URL,
    isEnabled: METRICS_IS_ENABLED,
    prefix: 'country_selection_ui',
    globalTags,
  });

  metrics.sendPagePerformance();

  const analytics = createAnalyticsHelper({ brand, environment });

  analytics.globalLandingPageLoaded();

  const linkSelection = window.localStorage.getItem(
    REDIRECT_SELECTION_LOCAL_STORAGE_KEY,
  );

  if (linkSelection) {
    window.location.assign(linkSelection);
    return null;
  }

  hydrateRoot(
    document.getElementById('app')!,
    <MelwaysProvider.Client site={site}>
      <AnalyticsProvider analytics={analytics}>
        <App brand={brand} />
      </AnalyticsProvider>
    </MelwaysProvider.Client>,
  );
};
