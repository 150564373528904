import type { Brand } from '@seek/melways-sites';
import { Box } from 'braid-design-system';
import React from 'react';

import { JDBBrandLogo } from '../../Logo/JDBBrandLogo';
import { JSBrandLogo } from '../../Logo/JSBrandLogo';

export const Header = ({ brand }: { brand: Brand }) => (
  <Box
    padding={['small', 'medium']}
    paddingX={['xlarge', 'xxlarge']}
    data-testid="tenant-logo"
  >
    {brand === 'jobstreet' ? <JSBrandLogo /> : <JDBBrandLogo />}
  </Box>
);
