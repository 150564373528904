import { IconFlagIdSvg } from 'src/icons/IconFlagIdSvg';
import { IconFlagMySvg } from 'src/icons/IconFlagMySvg';
import { IconFlagPhSvg } from 'src/icons/IconFlagPhSvg';
import { IconFlagSgSvg } from 'src/icons/IconFlagSgSvg';
import { IconFlagVnSvg } from 'src/icons/IconFlagVnSvg';
import type { CountryExtended, CountryLink } from 'src/types';

export const countryLinks = (
  countryUrls: Record<CountryExtended, string>,
): CountryLink[] => {
  const jobstreetIcmpid = 'icmpid=js_global_landing_page';
  const defaultCountrySequence = ['my', 'sg', 'ph', 'id', 'vn'];

  const countryLinkMap: Record<string, CountryLink> = {
    my: {
      title: 'Malaysia',
      url: `${countryUrls.my}?${jobstreetIcmpid}`,
      iconFlag: IconFlagMySvg,
    },
    sg: {
      title: 'Singapore',
      url: `${countryUrls.sg}?${jobstreetIcmpid}`,
      iconFlag: IconFlagSgSvg,
    },
    ph: {
      title: 'Philippines',
      url: `${countryUrls.ph}?${jobstreetIcmpid}`,
      iconFlag: IconFlagPhSvg,
    },
    id: {
      title: 'Indonesia',
      url: `${countryUrls.id}?${jobstreetIcmpid}`,
      iconFlag: IconFlagIdSvg,
    },
    vn: {
      title: 'Vietnam',
      url: `${countryUrls.vn}?${jobstreetIcmpid}`,
      iconFlag: IconFlagVnSvg,
    },
  };
  return defaultCountrySequence.map(
    (countryName) => countryLinkMap[countryName] || countryLinkMap.my,
  );
};
