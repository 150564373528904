import {
  Box,
  IconChevron,
  IconLocation,
  MenuItemLink,
  MenuRenderer,
  Text,
} from 'braid-design-system';
import React from 'react';

import { partners } from 'src/brands/seek/partners';
import type { LinkProps } from 'src/types';

export const InternationalPartners = () => {
  const { label, links } = partners;
  return (
    <MenuRenderer
      align="left"
      offsetSpace="small"
      placement="top"
      trigger={(triggerProps, { open }) => (
        <Box {...triggerProps} cursor="pointer" width="full">
          <Text>
            <IconLocation /> {label}{' '}
            <IconChevron direction={open ? 'down' : 'up'} alignY="lowercase" />
          </Text>
        </Box>
      )}
    >
      {links.map(({ title, url }: LinkProps) => (
        <MenuItemLink href={url} key={title}>
          {title}
        </MenuItemLink>
      ))}
    </MenuRenderer>
  );
};
