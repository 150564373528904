import type { Brand } from '@seek/melways-sites';

import { countryLinks as jdbCountryLinks } from 'src/brands/jobsdb/countryLinks';
import { countryLinks as jsCountryLinks } from 'src/brands/jobstreet/countryLinks';
import type { CountryLink } from 'src/types';
import { useCountryUrls } from 'src/utils/useCountryUrls';

export const useCountryLinks = (brand: Brand): CountryLink[] => {
  const countryUrls = useCountryUrls();

  return brand === 'jobstreet'
    ? jsCountryLinks(countryUrls)
    : jdbCountryLinks(countryUrls);
};
