import React, { type SVGAttributes } from 'react';

export const IconFlagHkSvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="#ff2b2d"
      d="M7.88 9.48c.06.44.44.83 1 .67.62-.17 1.43.3 1.52 1.06.07.55.66 1.18 1.43.87l.06-.05c0-.01-.01-.02-.01-.03 0-.01 0-.02.01-.03-.03-.02-.05-.04-.08-.05-1.21-.6-1.7-2.07-1.09-3.27.45-.9 1.4-1.43 2.4-1.34-.4.2-.65.67-.33 1.16.36.54.16 1.45-.54 1.77-.51.24-.92.99-.39 1.63.02.02.04.03.07.04.02-.01.04-.02.06-.02l.03-.09c.2-1.33 1.44-2.25 2.78-2.05.99.15 1.79.89 2.01 1.87-.31-.32-.84-.42-1.21.05-.4.51-1.33.6-1.85.04-.38-.41-1.23-.57-1.67.13-.01.02-.02.05-.02.08.02.01.03.03.04.04h.1c1.33-.22 2.58.68 2.8 2.01.16.99-.29 1.97-1.15 2.49.21-.39.13-.93-.42-1.13-.61-.22-.98-1.08-.61-1.75.27-.49.16-1.34-.64-1.54h-.08c-.01.02-.02.03-.03.05l.03.1c.58 1.13.2 2.51-.87 3.19-.91.57-2.08.48-2.89-.23.43.08.92-.16.95-.75.03-.65.72-1.27 1.48-1.12.55.1 1.33-.26 1.27-1.09-.01-.02-.02-.05-.03-.07-.02 0-.04-.01-.05-.02-.03.02-.06.04-.08.06-.95.96-2.49.96-3.45.01a2.42 2.42 0 0 1-.55-2.69zM0 20.04h24V3.96H0v16.08z"
    />
    <path
      fill="#fff"
      d="M11.61 8.71l.22.19.25-.15-.12.27.22.19-.28-.02-.12.27-.06-.28-.29-.03.25-.15-.07-.29zm-.15.73l.06.04c-.44.65-.42 1.8.36 2.39-.54-.64-.12-1.39.39-1.63.69-.32.89-1.23.54-1.77-.33-.49-.07-.97.33-1.16-1.34-.11-2.52.88-2.64 2.22-.08.99.44 1.94 1.33 2.39-.85-.65-.79-1.88-.37-2.48z"
    />
    <path
      fill="#ff1c1f"
      d="M11.72 9.17l.06.29.12-.27.29.03-.22-.19.12-.27-.26.14-.22-.19.06.29-.25.15.3.02zm.22 2.74l-.07-.04c-.78-.59-.79-1.74-.36-2.39l-.06-.04c-.42.6-.48 1.83.36 2.47l.08.05c.02-.01.03-.03.05-.05"
    />
    <path
      fill="#fff"
      d="M14.6 10.86l-.06-.29.22.19.25-.15-.12.27.22.19-.29-.03-.12.27-.06-.29-.29-.03.25-.13zm-.34-.16l-.02.07c-.75-.22-1.84.15-2.16 1.08.44-.71 1.28-.54 1.67-.13.52.56 1.45.47 1.85-.04.37-.46.9-.36 1.21-.04a2.44 2.44 0 0 0-2.92-1.83c-.98.22-1.72 1.02-1.87 2.01.36-1.02 1.54-1.34 2.24-1.12z"
    />
    <path
      fill="#ff1c1f"
      d="M14.71 11.32l.12-.27.29.03-.22-.19.12-.27-.25.15-.22-.19.06.29-.25.15.29.03.06.27zm-2.65.6l.02-.08c.32-.92 1.41-1.29 2.16-1.08l.02-.07c-.7-.21-1.89.11-2.24 1.11l-.03.09c.03 0 .05.01.07.03"
    />
    <path
      fill="#fff"
      d="M9 11.2l-.06-.28.22.19.25-.15-.12.27.22.19-.29-.03-.12.27-.06-.29-.29-.03.25-.14zm-.44 1.1c.97.81 2.41.75 3.3-.15-.87.6-2.02.16-2.46-.42l.05-.04c.48.62 1.58.96 2.38.4-.77.31-1.36-.32-1.43-.87-.09-.76-.9-1.23-1.52-1.06-.57.16-.94-.23-1-.67-.42.98-.14 2.12.68 2.81z"
    />
    <path
      fill="#ff1c1f"
      d="M9.11 11.66l.12-.27.29.03-.22-.19.12-.27-.25.15-.22-.19.05.28-.25.15.29.03.07.28zm.35.02l-.06.05c.44.58 1.59 1.03 2.46.42l.08-.06a.102.102 0 0 1-.05-.06c-.02.02-.04.03-.06.05-.79.56-1.89.22-2.37-.4"
    />
    <path
      fill="#fff"
      d="M14.25 14.44l-.29-.03-.11.27-.06-.29-.29-.03.25-.15-.06-.29.22.19.25-.15-.12.27.21.21zm-.31-.69h-.07c-.03-.78-.71-1.7-1.69-1.72.81.2.91 1.06.64 1.54-.37.67 0 1.53.61 1.75.55.21.62.74.42 1.13a2.44 2.44 0 0 0 .84-3.35c-.51-.86-1.5-1.31-2.49-1.15 1.06.04 1.73 1.07 1.74 1.8z"
    />
    <path
      fill="#ff1c1f"
      d="M13.89 14.12l-.22-.19.07.29-.25.15.29.03.06.29.12-.27.29.03-.22-.19.12-.27-.26.13zM12.11 12c0 .01 0 .02-.01.04h.08c.98.02 1.66.94 1.69 1.72h.07c-.01-.73-.68-1.76-1.74-1.79h-.1s.01.02.01.03"
    />
    <path
      fill="#fff"
      d="M10.38 14.89l-.06-.29-.29-.03.25-.15-.06-.28.22.19.25-.15-.12.27.22.19-.29-.03-.12.28zm.56-.5l-.03-.06c.74-.27 1.4-1.21 1.11-2.14.06.83-.72 1.19-1.27 1.09-.75-.15-1.45.47-1.48 1.12-.03.59-.51.82-.95.75 1.02.88 2.56.77 3.44-.25.65-.75.78-1.82.33-2.71.31 1-.46 1.96-1.15 2.2z"
    />
    <path
      fill="#ff1c1f"
      d="M10.57 14.46l.12-.27-.25.15-.22-.19.06.28-.25.15.29.03.06.29.12-.27.29.03-.22-.2zM12 12.11c.01.02.02.05.03.07.28.93-.38 1.87-1.12 2.14l.03.07c.69-.24 1.46-1.19 1.16-2.21-.01-.03-.02-.06-.03-.1-.02.02-.05.03-.07.03"
    />
    <path
      fill="#ff1c1f"
      d="M11.94 12.09c.02.01.04.02.05.02.05 0 .09-.03.1-.07.01-.02.01-.05 0-.07a.078.078 0 0 0-.04-.04c-.02-.01-.04-.02-.06-.02-.05 0-.09.03-.1.08 0 .01-.01.02-.01.03 0 .01 0 .02.01.03.02.01.03.03.05.04"
    />
  </svg>
);
