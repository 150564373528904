import React, { type SVGAttributes } from 'react';

export const IconFlagVnSvg = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#ec0015" d="M.44 3.33h23.11v17.33H.44V3.33z" />
    <path
      fill="#ff0"
      d="M14.81 15.9l-2.84-2.12-2.82 2.14 1.05-3.48-2.82-2.14 3.49-.03 1.08-3.47 1.1 3.46h3.49l-2.8 2.17 1.07 3.47z"
    />
    <path
      fill="#ccc"
      d="M24 21.11H0V2.89h24v18.22zM.89 20.22h22.22V3.78H.89v16.44z"
    />
  </svg>
);
