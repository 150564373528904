import { Logo } from '@seek/logo';
import { Box } from 'braid-design-system';

import type { LogoProperties } from 'src/types';

const brand = 'jobstreet';
const defaultVariant = 'standard';
const defaultSizeType = 'standard';

export const JSBrandLogo = (logoProps: LogoProperties) => (
  <Box style={logoProps} data-testid="jobstreet-logo">
    <Logo
      variant={defaultVariant}
      brand={brand}
      size={logoProps?.height ? 'fill' : defaultSizeType}
    />
  </Box>
);
